"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.from.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.date.to-string.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.iterator.js");

require("core-js/modules/es.string.replace.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _getDay = _interopRequireDefault(require("date-fns/getDay"));

var _getYear = _interopRequireDefault(require("date-fns/getYear"));

var _getMonth = _interopRequireDefault(require("date-fns/getMonth"));

var _getDate = _interopRequireDefault(require("date-fns/getDate"));

var _endOfMonth = _interopRequireDefault(require("date-fns/endOfMonth"));

var _getHours = _interopRequireDefault(require("date-fns/getHours"));

var _getMinutes = _interopRequireDefault(require("date-fns/getMinutes"));

var _getSeconds = _interopRequireDefault(require("date-fns/getSeconds"));

var _addYears = _interopRequireDefault(require("date-fns/addYears"));

var _addMonths = _interopRequireDefault(require("date-fns/addMonths"));

var _addDays = _interopRequireDefault(require("date-fns/addDays"));

var _setYear = _interopRequireDefault(require("date-fns/setYear"));

var _setMonth = _interopRequireDefault(require("date-fns/setMonth"));

var _setDate = _interopRequireDefault(require("date-fns/setDate"));

var _setHours = _interopRequireDefault(require("date-fns/setHours"));

var _setMinutes = _interopRequireDefault(require("date-fns/setMinutes"));

var _setSeconds = _interopRequireDefault(require("date-fns/setSeconds"));

var _isAfter = _interopRequireDefault(require("date-fns/isAfter"));

var _isValid = _interopRequireDefault(require("date-fns/isValid"));

var _getWeek2 = _interopRequireDefault(require("date-fns/getWeek"));

var _startOfWeek = _interopRequireDefault(require("date-fns/startOfWeek"));

var _format2 = _interopRequireDefault(require("date-fns/format"));

var _parse = _interopRequireDefault(require("date-fns/parse"));

// typescript import
// import
// FIXME: https://github.com/react-component/picker/issues/232
// definition
var localeParse = function localeParse(format) {
  return format.replace(/Y/g, 'y').replace(/D/g, 'd').replace(/gggg/, 'yyyy').replace(/g/g, 'G').replace(/([Ww])o/g, 'wo');
};

var generateConfig = function generateConfig(dateFnsLocale) {
  return {
    // get
    getNow: function getNow() {
      return new Date();
    },
    getFixedDate: function getFixedDate(string) {
      return new Date(string);
    },
    getEndDate: _endOfMonth["default"],
    getWeekDay: _getDay["default"],
    getYear: _getYear["default"],
    getMonth: _getMonth["default"],
    getDate: _getDate["default"],
    getHour: _getHours["default"],
    getMinute: _getMinutes["default"],
    getSecond: _getSeconds["default"],
    // set
    addYear: _addYears["default"],
    addMonth: _addMonths["default"],
    addDate: _addDays["default"],
    setYear: _setYear["default"],
    setMonth: _setMonth["default"],
    setDate: _setDate["default"],
    setHour: _setHours["default"],
    setMinute: _setMinutes["default"],
    setSecond: _setSeconds["default"],
    // Compare
    isAfter: _isAfter["default"],
    isValidate: _isValid["default"],
    locale: {
      getWeekFirstDay: function getWeekFirstDay() {
        var _dateFnsLocale$option;

        return ((_dateFnsLocale$option = dateFnsLocale.options) === null || _dateFnsLocale$option === void 0 ? void 0 : _dateFnsLocale$option.weekStartsOn) || 1;
      },
      getWeekFirstDate: function getWeekFirstDate(_, date) {
        return (0, _startOfWeek["default"])(date, {
          locale: dateFnsLocale
        });
      },
      getWeek: function getWeek(_, date) {
        return (0, _getWeek2["default"])(date, {
          locale: dateFnsLocale
        });
      },
      getShortWeekDays: function getShortWeekDays() {
        return Array.from({
          length: 7
        }).map(function (_, i) {
          var _dateFnsLocale$locali;

          return (_dateFnsLocale$locali = dateFnsLocale.localize) === null || _dateFnsLocale$locali === void 0 ? void 0 : _dateFnsLocale$locali.day(i, {
            width: 'short'
          });
        });
      },
      getShortMonths: function getShortMonths() {
        return Array.from({
          length: 12
        }).map(function (_, i) {
          var _dateFnsLocale$locali2;

          return (_dateFnsLocale$locali2 = dateFnsLocale.localize) === null || _dateFnsLocale$locali2 === void 0 ? void 0 : _dateFnsLocale$locali2.month(i, {
            width: 'abbreviated'
          });
        });
      },
      format: function format(_, date, _format) {
        if (!(0, _isValid["default"])(date)) return '';
        return (0, _format2["default"])(date, localeParse(_format), {
          locale: dateFnsLocale
        });
      },
      parse: function parse(_, text, formats) {
        for (var i = 0; i < formats.length; i += 1) {
          var format = localeParse(formats[i]);
          var formatText = text;
          var date = (0, _parse["default"])(formatText, format, new Date(), {
            locale: dateFnsLocale
          });
          if ((0, _isValid["default"])(date)) return date;
        }

        return null;
      }
    }
  };
};

var _default = generateConfig;
exports["default"] = _default;